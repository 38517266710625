import React from 'react'

const InfoCard = ({ title, content }) => {
  return (
    <div>
      <div className='text-center w-[80%] p-3 my-6 shadow-lg rounded border border-gray-300 border-solid container mx-auto dark:bg-gray-800'>
        <div className='flex justify-center items-center flex-col'>
          {title && (
            <h3 className='pt-3 font-semibold uppercase md:text-2xl sm:text-1xl text-red-600 dark:text-red-400'>
              {title}
            </h3>
          )}
          <p className='pb-3 mt-6 md:w-[50%] md:mx-auto font-semibold uppercase sm:text-xs md:text-sm text-gray-600 dark:text-gray-400'>
            {content}
          </p>
        </div>
      </div>
    </div>
  )
}

export default InfoCard
