import React, { useState, useEffect } from 'react'
import { MdMailOutline } from 'react-icons/md'
import { getStrapiPrivateEvents } from '../data/getStrapiPrivateEvents'
import InfoCard from '../components/infoCard'

const PrivateEvents = () => {
  const [privateEvents, setPrivateEvents] = useState([])
  const collectionData = 'private-events?populate=*'

  useEffect(() => {
    const fetchStrapi = async () => {
      const data = await getStrapiPrivateEvents(collectionData)
      setPrivateEvents(data)
    }
    fetchStrapi()
  }, [])

  return (
    <div className='mb-10'>
      {privateEvents?.map(({ id, heroTitle, heroContent, heroImage, privateEventsAssets, cateringTitle, cateringContent, inHouseTitle, inHouseContent, cookingTitle, cookingContent, considerations, emailUs }) => (
        <React.Fragment key={id}>
          <div className='w-full bg-cover bg-center md:bg-bottom' style={{ backgroundImage: `url(${heroImage})`, height: '100dvh' }}>
            <div className='flex items-center flex-col justify-center h-screen w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60'>
              <h1 className='pb-3 text-dark text-white text-2xl font-semibold uppercase md:text-3xl'>{heroTitle}</h1>
              <div className='text-center p-3 m-3 rounded backdrop-blur-xl shadow-lg'>
                <p className='pb-3 sm:md:text-2md text-base font-semibold uppercase text-white dark:text-gray-600'>{heroContent}</p>
              </div>
            </div>
          </div>

          <div className='flex flex-row justify-between 2xl:justify-evenly items-center gap-8 px-[6rem] py-[2rem]'>
            {privateEventsAssets?.map(({ privateAsset, id }) => (
              <img
                src={privateAsset}
                className='w-[45%] 2xl:w-[20%] rounded-md'
                alt='joys private events' key={id}
              />
            ))}
          </div>

          <div>
            <InfoCard title={cateringTitle} content={cateringContent} />
          </div>
          <div>
            <InfoCard title={inHouseTitle} content={inHouseContent} />
          </div>
          <div>
            <InfoCard title={cookingTitle} content={cookingContent} />
          </div>
          <div>
            <InfoCard title='' content={considerations} />
          </div>
          <div className='flex flex-col gap-4 justify-center items-center'>
            <p className='pb-3 mt-6 text-center font-semibold uppercase sm:text-xs md:text-sm text-gray-600 dark:text-gray-400'>
              {emailUs}
            </p>
            {/* eslint-disable */}
            <button type='button' onClick={(e) => {
                window.location.href = 'mailto:thewokofjoy@gmail.com' ;
                e.preventDefault();
              }}
             className='px-6 py-3.5 gap-3 text-base font-medium text-white inline-flex items-center bg-[#DC2625] hover:bg-[#dc2525b0] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center'>
              <MdMailOutline />
              Send us an Email
            </button>
            {/* eslint-enable */}
          </div>

        </React.Fragment>
      ))}
    </div>
  )
}

export default PrivateEvents
