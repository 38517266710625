import React, { useState, useEffect } from 'react'
import CardMenu from '../components/CardMenu'
import { getStrapiCollections } from '../data/getStrapiColletions'

const NewMenu = () => {
  const [mealsWok, setMealsWok] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiCollections('wok-menus')
      const sortedData = data.slice().sort((a, b) => a.id - b.id)
      setMealsWok(sortedData)
    }
    fetchData()
  }, [])

  return (
    <div className='bg-neutral-100 dark:bg-slate-800'>
      <div className='container mx-auto text-center'>
        <h2 className='sm:md:text-3xl text-xl font-medium text-gray-800/90 dark:text-gray-100 capitalize m-5'>
          MENU <br /> (PRICE INCLUDES ALL TAXES)
        </h2>
        <div className='flex justify-center items-center mb-4'>
          <p className='text-sm text-gray-600 dark:text-gray-200 bg-red-100 dark:bg-red-900/70 rounded-xl w-1/2 p-1 m-1'>
            <strong>Please bring your own bag. please.</strong>
          </p>
        </div>
        <hr className='text-slate-600 dark:text-slate-900 my-2' />
        {mealsWok?.map(({ id, category, description, meals, header }) => (
          <div className='mx-5 my-8' key={id} id={id}>
            <h2 className='sm:md:text-3xl text-4xl font-medium text-gray-900 dark:text-gray-200/70 capitalize p-2'>{header}</h2>
            {description?.length > 0 && (<p id={`${id}-description`} className='text-gray-600 dark:text-gray-400'> {description} </p>)}
            <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:xl:grid-cols-3 sm:md:mx-0 mx-5 gap-2 text-center justify-items-center' id={category}>
              {/* eslint-disable */}
              {meals.map(({ title, id, index, brief_description, ingredients }) => (
                 <div key={id} className='flex justify-center items-center my-3 max-w-sm w-full rounded-lg dark:bg-slate-600/50 ring-1 ring-slate-900/5 shadow-md bg-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-200 h-[160px]'>
                  <CardMenu title={title} subtitle={brief_description} section={header} description={ingredients} />
                </div>
              ))}
              {/* eslint-enable */}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default NewMenu
