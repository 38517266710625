export const getStrapiHome = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
    }
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        heroTitle: attributes?.hero_title,
        cardTitle: attributes?.card_title,
        facebookLabel: attributes?.facebook_label,
        plantswitchLogo: process.env.REACT_APP_STRAPI_URL + attributes?.plantswitch_logo?.data?.attributes?.url,
        plantswitchTitle: attributes?.planswitch_title,
        plantswitchSubtitle: attributes?.planswitch_subtitle,
        subtitleOne: attributes?.subtitle_one,
        subtitleTwo: attributes?.subtitle_two,
        subtitleSchedule: attributes?.subtitle_schedule,
        subtitleDays: attributes?.subtitle_days
      }
    }
  )

  return values
}
