import bgmenu from '../assets/2024/portrait.png'
import bgmenusm from '../assets/2024/portraitsm.png'
import Curries from '../assets/2024/curry.jpeg'
import Noodles from '../assets/2024/pad thai.jpeg'
import Stir from '../assets/2024/stir fry.jpg'
import Kanomone from '../assets/2024/app.jpg'
import Kanomtwo from '../assets/2024/app2.jpg'

const Card = ({ text, section, image }) => {
  return (
    <div className='my-3 md:w-[284px] w-[384px] bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700'>
      <div className='flex flex-col items-center p-10'>
        <img className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-125 duration-300 mb-3 rounded w-[6rem] h-[6rem] shadow-lg' src={image} alt={text} />
        <h5 className='mb-1 text-xl font-medium text-gray-900 dark:text-white'>{text}</h5>
        <span className='text-sm text-gray-500 dark:text-gray-400'>{section}</span>
      </div>
    </div>
  )
}

const JoysFood = () => {
  return (
    <div className='bg-neutral-100 dark:bg-slate-900'>
      <header>
        <div className='w-full hidden md:block bg-cover bg-center' style={{ backgroundImage: `url(${bgmenu})`, height: '32rem' }}>
          <div className='flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60'>
            <div>
              <div className='text-center p-3 m-3'>
                <h1 className='pb-3 text-dark text-white text-2xl font-semibold uppercase md:text-3xl'>Telluride’s Thai Restaurant</h1>
              </div>
              <div className='text-center p-3 m-3 rounded backdrop-blur-xl shadow-lg'>
                <p className='pb-3 sm:md:text-2md text-xs font-semibold uppercase text-white dark:text-gray-600'>
                  JOY’S FOOD IS COOKED IN THE TRADITION OF HER EXTENDED <br />
                  FAMILY IN THAILAND - A MIX OF THAI HOME COOKING AND <br />
                  CLASSIC THAI STREET FOOD. <br />
                  <strong>ITEMS ON THE DAILY MENU MAY SELL OUT AT SOME POINT</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full md:hidden block bg-cover bg-center' style={{ backgroundImage: `url(${bgmenusm})`, height: '32rem' }}>
          <div className='flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60'>
            <div>
              <div className='text-center p-3 m-3'>
                <h1 className='pb-3 text-dark text-white text-2xl font-semibold uppercase md:text-3xl'>Telluride’s Thai Restaurant</h1>
              </div>
              <div className='text-center p-3 m-3 rounded backdrop-blur-xl shadow-lg'>
                <p className='pb-3 sm:md:text-2md text-xs font-semibold uppercase text-white dark:text-gray-600'>
                  JOY’S FOOD IS COOKED IN THE TRADITION OF HER EXTENDED <br />
                  FAMILY IN THAILAND - A MIX OF THAI HOME COOKING AND <br />
                  CLASSIC THAI STREET FOOD. <br />
                  <strong>ITEMS ON THE DAILY MENU MAY SELL OUT AT SOME POINT</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='container mx-auto px-4 py-8'>
        <section className='text-center p-3 m-3'>
          <div className='text-center p-3 m-3 rounded container mx-auto'>
            <h5 className='pb-3 text-1xl font-semibold uppercase md:text-2xl sm:text-1xl text-red-900 dark:text-red-400'>
              CURRIES
            </h5>
            <div className='md:w-[80%] md:mx-auto'>
              <p className='pb-3 font-semibold uppercase text-gray-600 dark:text-gray-400'>
                JOY’S CURRIES ARE WELL-KNOWN IN TELLURIDE FOR THEIR HEAT AND DEPTH OF FLAVOR. WE IMPORT OUR CURRY PASTE FROM HER HOMETOWN IN CENTRAL THAILAND SO JOY’S CURRIES ARE NATURALLY SPICY AND THAT SPICE LEVEL CANNOT BE TONED DOWN.
              </p>
              <p className='pb-3 font-semibold uppercase text-gray-600 dark:text-gray-400'>
                SOME OF OUR CURRIES ARE TRADITIONAL COCONUT CREAM BASED, SOME ARE DRY STIR-FRIED CURRIES, AND OCCASIONALLY A CLASSIC NOODLE CURRY FROM THE NORTH LIKE KHAO SOI.
              </p>
              <p className='pb-3 font-semibold uppercase text-gray-600 dark:text-gray-400'>
                GLUTEN-FREE. SPICY. <br /> <strong>… NO, WE DON’T DO SPICE LEVELS …</strong>
              </p>
            </div>
          </div>
          <div className='flex flex-col md:flex-row justify-center md:justify-around items-center gap-4 w-full'>
            <Card text='Curries' image={Curries} section='THAI CURRIES' />
          </div>
        </section>
        <section className='text-center p-3 m-3'>
          <div className='text-center p-3 m-3 rounded container mx-auto'>
            <h5 className='pb-3 text-1xl font-semibold uppercase md:text-2xl sm:text-1xl text-red-900 dark:text-red-400'>
              PAD THAI NOODLES
            </h5>
            <div className='md:w-[80%] md:mx-auto'>
              <p className='pb-3 font-semibold uppercase text-gray-600 dark:text-gray-400'>
                WE USE FRESH RICE NOODLES IN OUR PAD THAI ALONG WITH JOY’S HOMEMADE TAMARIND SAUCE. <br />NO FISH SAUCE. NO SOY SAUCE.
              </p>
              <p className='pb-3 font-semibold uppercase text-gray-600 dark:text-gray-400'>
                THIS IS THE CLASSIC STREET VERSION OF PAD THAI. NOODLES - SLIGHTLY SWEET SOUR SAUCE - FRESH MUNG BEAN SPROUTS - GREEN ONION - A LIME WEDGE ON THE SIDE GLUTEN-FREE. NOT SPICY.
              </p>
            </div>
          </div>
          <div className='flex flex-col md:flex-row justify-center md:justify-around items-center gap-4 w-full'>
            <Card text='Pad Thai Nodles' image={Noodles} section='PAD THAI NOODLES' />
          </div>
        </section>
        <section className='text-center p-3 m-3'>
          <div className='text-center p-3 m-3 rounded container mx-auto'>
            <h5 className='pb-3 text-1xl font-semibold uppercase md:text-2xl sm:text-1xl text-red-900 dark:text-red-400'>
              STIR-FRIED DISHES
            </h5>
            <div className='md:w-[55%] md:mx-auto'>
              <p className='pb-3 font-semibold uppercase text-gray-600 dark:text-gray-400'>
                STIR-FRIES ARE A HUGE PART OF THE THAI FOOD SCENE. <br /> OUR STIR-FRIED DISHES ARE COOKED EACH TIME TO ORDER FROM SCRATCH IN THE WOK USING PARTS OF THE 5 ASIAN FLAVOR ELEMENTS:<br /> SWEET, SALTY, SOUR, SPICY, AND UNAMI. THAI JASMINE RICE IS ALWAYS SERVED ALONGSIDE.
              </p>
            </div>
          </div>
          <div className='flex flex-col md:flex-row justify-center md:justify-around items-center gap-4 w-full'>
            <Card text='Stir-Fried Dishes' image={Stir} section='Stir-Fried Dishes' />
          </div>
        </section>
        <section className='text-center p-3 m-3'>
          <div className='text-center p-3 m-3 rounded container mx-auto'>
            <h5 className='pb-3 text-1xl font-semibold uppercase md:text-2xl sm:text-1xl text-red-900 dark:text-red-400'>
              KA-NOM (SNACKS)
            </h5>
            <div className='md:w-[55%] md:mx-auto'>
              <p className='pb-3 font-semibold uppercase text-gray-600 dark:text-gray-400'>
                CLASSIC THAI SNACKS YOU MIGHT FIND AT THE FOOD TRAILER INCLUDE FRIED GYOZA DUMPLINGS, STEAMED SHUMAI DUMPLINGS, FRIED EGGROLLS, STEAMED BAO BUNS, AND IN SEASON MANGO STICKY RICE.
              </p>
            </div>
          </div>
          <div className='flex flex-col md:flex-row justify-center items-center gap-4 w-full'>
            <Card text='BAO BUNS' image={Kanomone} section='KA-NOM' />
            <Card text='STEAMED DUMPLINGS' image={Kanomtwo} section='KA-NOM' />
          </div>
        </section>
      </div>
    </div>
  )
}

export default JoysFood
