import { Routes, Route } from 'react-router-dom'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import JoysStory from './routes/JoysStory'
import ContactUs from './routes/ContactUs'
import NoPlastics from './routes/NoPlastics'
import Home from './routes/Home'
import JoysFood from './routes/JoysFood'
import NewMenu from './routes/NewMenu'
import PrivateEvents from './routes/PrivateEvents'

const App = () => {
  return (
    <div className='flex flex-col h-screen justify-between antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-800'>
      <Navbar className='h-10' />
      <Routes className='mb-auto h-10'>
        <Route path='/' element={<Home />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/joysstory' element={<JoysStory />} />
        <Route path='/joysfood' element={<JoysFood />} />
        <Route path='/no_plastics' element={<NoPlastics />} />
        <Route path='/private_events' element={<PrivateEvents />} />
        <Route path='/currentmenu' element={<NewMenu />} />
        <Route path='*' element={<Home />} />
      </Routes>
      <Footer className='h-10' />
    </div>
  )
}

export default App
