import React, { useState, useEffect } from 'react'
import { getStrapiHome } from '../data/getStrapiHome'
import '../App.css'

const Home = () => {
  const [homeData, setHomeData] = useState([])
  const collectionData = 'homes?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHome(collectionData)
      setHomeData(data)
    }
    fetchData()
  }, [])

  return (
    <>
      {homeData?.map(({ id, heroTitle, plantswitchSubtitle, plantswitchTitle, subtitleOne, subtitleTwo, subtitleSchedule, subtitleDays }) => (
        <React.Fragment key={id}>
          <article className='bg-slate-800 bg-opacity-40 dark:bg-opacity-60'>
            <div className='video-wrapper'>
              <video className='h-full' id='video' muted autoPlay playsInline loop>
                <source src='https://the-wok-of-joy.nyc3.digitaloceanspaces.com/wok-hero.mp4' type='video/mp4' />
              </video>
            </div>
          </article>
          <div className='text-center flex items-center  justify-center  p-2 h-full backdrop-blur-xl dark:bg-slate-900/20 bg-slate-900/80 w-full'>
            <h1 className='text-white dark:text-slate-100 text-sm font-semibold uppercase md:text-2xl'> {heroTitle} </h1>
          </div>
          <div className='w-full bg-cover bg-center'>
            <div className='justify-center h-full w-full bg-slate-800 bg-opacity-10 dark:bg-opacity-70'>
              <div className='grid grid-cols-1 justify-items-center'>
                <div>
                  <div className='text-center p-5 m-5 rounded bg-gray-100 dark:bg-gray-900'>
                    <div className='organic-container md:w-[70%] md:mx-auto'>
                      <p className='pb-2 text-md font-semibold uppercase md:text-3md text-red-900 dark:text-red-400'> {plantswitchTitle} </p>
                      <p className='pb-6 text-sm font-semibold uppercase md:text-3md text-red-900 dark:text-red-400'> {plantswitchSubtitle} </p>
                    </div>
                    <div>
                      <p className='text-2md font-bold subpixel-antialiased font-serif uppercase md:text-3md text-black dark:text-neutral-100'> {subtitleOne} </p>
                      <p className='text-2md font-bold subpixel-antialiased font-serif uppercase md:text-3md text-black dark:text-neutral-100'> {subtitleTwo} </p>
                      <p className='text-2md font-bold subpixel-antialiased font-serif uppercase md:text-3md text-black dark:text-neutral-100'>{subtitleSchedule}</p>
                      <p className='text-2md font-bold subpixel-antialiased font-serif uppercase md:text-3md text-black dark:text-neutral-100'>{subtitleDays}</p>

                      <p className='text-2md mt-[1rem] font-bold subpixel-antialiased font-serif uppercase md:text-3md text-black dark:text-neutral-100'>PROPER THAI</p>
                      <p className='text-2md font-bold subpixel-antialiased font-serif uppercase md:text-3md text-black dark:text-neutral-100'>NO BABY CORN</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  )
}

export default Home
