export const getStrapiPrivateEvents = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
    }
  })).json()

  const values = collection?.data?.map(({ attributes, id }) => {
    return {
      id: id,
      heroTitle: attributes?.hero_title,
      heroContent: attributes?.hero_subtitle,
      heroImage: process.env.REACT_APP_STRAPI_URL + attributes?.hero_image.data.attributes.url,
      privateEventsAssets: attributes.private_events_assets?.data.map(({ attributes, id }) => ({
        id: id,
        privateAsset: process.env.REACT_APP_STRAPI_URL + attributes?.url
      })),
      cateringTitle: attributes?.catering_title,
      cateringContent: attributes?.catering_content,
      inHouseTitle: attributes?.in_house_title,
      inHouseContent: attributes?.in_house_content,
      cookingTitle: attributes?.cooking_class_title,
      cookingContent: attributes?.cooking_class_content,
      considerations: attributes?.considerations,
      emailUs: attributes?.email_us
    }
  })

  return values
}
